import { useCallback, useRef } from "react";
import { IReactToPrintProps, useReactToPrint } from "react-to-print";

function usePrint(props: Partial<IReactToPrintProps> = {}) {
  const printableComponentRef = useRef(null);

  const reactToPrintContent = useCallback(() => {
    return printableComponentRef.current;
  }, [printableComponentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Motoparts.md Cart",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: false,
    pageStyle: `
      @media print {
        html {
          font-size: 10px;
        }
      }
    `,
    ...props,
  });

  return {
    printableComponentRef,
    handlePrint,
  };
}

export default usePrint;
