import React from 'react';
import { DateTime as Luxon } from 'luxon';
import { Typography, TypographyProps } from '@mui/material';

interface DateTimeProps extends TypographyProps {
  component?: string;
  value: string | number | null | undefined;
  timeOnly?: boolean;
  dateOnly?: boolean;
  utcToLocal?: boolean;
}

export function dateTimeFormat(value?: string, format: string = 'F', utcToLocal: boolean = false): string {
  if (!value) return '';
  return utcToLocal ? Luxon.fromISO(`${value}.000Z`).toLocal().toFormat(format) : Luxon.fromISO(value).toFormat(format);
}

function getFormat(timeOnly: boolean = false, dateOnly: boolean = false): string {
  if (timeOnly) return 'tt';
  if (dateOnly) return 'D';
  return 'F';
}

const DateTime: React.FC<DateTimeProps> = (props) => {
  const { timeOnly, dateOnly, utcToLocal, ...typographyProps } = props;
  let value = props.value;
  if (!value) {
    return null;
  }

  if (typeof value === 'number') {
    value = new Date(value).toISOString();
  }

  const format = getFormat(timeOnly, dateOnly);
  const date = dateTimeFormat(value, format, utcToLocal);

  return <Typography {...typographyProps}>{date}</Typography>;
};

export default DateTime;
