import { deepMerge } from "./object";

export const mergeStyles = (...styles: Nullable<Style>[]): Style => {
  return styles.reduce((acc: Style, item: Nullable<Style>) => {
    if (!item) return acc;
    if (item instanceof Function) console.warn("Freaking style", item);
    return deepMerge(acc, item);
  }, {} as Style);
};

export const ifStyles = (
  condition: Nullable<boolean>,
  style: Style = {},
  fallback: Style = {}
): Style => {
  return condition ? style : fallback;
};

export function mapStyles<T extends Record<string, Style>>(
  styles: T
): { [Prop in keyof T]: Style } {
  return styles;
}

export function alphaColor(color: string, opacity: number) {
  const alpha = Math.floor(opacity * 255)
    .toString(16)
    .padStart(2, "0");
  return `${color}${alpha}`.toUpperCase();
}
