import React, { useContext, useState } from "react";
import { Button, IconButton, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { mapStyles } from "../libs/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AppContext } from "../contexts/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import useBreakpoints from "../hooks/useBreakpoints";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorUtil } from "../libs/formikError";
import LoadingButton from "@mui/lab/LoadingButton";
import { SignInFormModel, signInRequest } from "../api/authApi";
import { getSnackOptions } from "../libs/snackbar";
import { AxiosError } from "axios";

const requiredMessage = "Acest câmp este obligatoriu";

const validationSchema = Yup.object({
  email: Yup.string().email().required(requiredMessage),
  password: Yup.string().required(requiredMessage),
});

const FORM_DEFAULT_VALUES: SignInFormModel = {
  email: "",
  password: "",
};

interface SignInDialogProps {
  open?: boolean;
  onClose?: (openRegistration: boolean) => void;
  sx?: Style;
}

const SignInDialog: React.FC<SignInDialogProps> = (props) => {
  const { open, onClose = () => {}, sx } = props;
  const [ctx, updateCtx] = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data: SignInFormModel) => {
    try {
      const res = await signInRequest(data);
      console.log(res);
      updateCtx(res.data ?? {});
      onClose(false);
      enqueueSnackbar(
        "",
        getSnackOptions("success", {
          heading: t("components.SignInDialog.signInSuccess") ?? "Success",
        })
      );
      navigate("/");
    } catch (e: any | AxiosError) {
      updateCtx({ jwt: null, user: null });
      enqueueSnackbar(
        e.response.data.error.message,
        getSnackOptions("error", {
          heading: t("components.SignInDialog.signInError") ?? "Error",
        })
      );
    }
  };

  const {
    values,
    handleSubmit,
    errors,
    touched,
    isValid,
    setValues,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormik({
    initialValues: FORM_DEFAULT_VALUES,
    validationSchema,
    validateOnMount: true,
    onSubmit: onSubmit,
  });

  const errorHandling = errorUtil(touched, errors);

  const handleChange = async (name: string, value: any, touch?: boolean) => {
    await setFieldValue(name, value);
    if (touch) {
      await setFieldTouched(name, true);
    }
  };

  const handleBlur = async (name: string, touch?: boolean) => {
    await setFieldTouched(name, touch);
  };

  if (!open) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        maxWidth="sm"
        fullScreen={isMobile}
        sx={styles.dialog}
      >
        <DialogTitle sx={styles.dialogTitle}>
          {t("components.SignInDialog.title")}

          <IconButton
            aria-label="close"
            onClick={() => onClose(false)}
            sx={styles.close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Stack sx={styles.form} component="form" onSubmit={handleSubmit}>
            <TextField
              label={t("components.SignInDialog.email")}
              value={values.email}
              onChange={(ev) => handleChange("email", ev.target.value)}
              onBlur={() => handleBlur("email", true)}
              error={errorHandling.isInvalid("email")}
              required
              sx={styles.input}
            />
            <TextField
              label={t("components.SignInDialog.password")}
              value={values.password}
              onChange={(ev) => handleChange("password", ev.target.value)}
              onBlur={() => handleBlur("password", true)}
              error={errorHandling.isInvalid("password")}
              required
              sx={styles.input}
              type="password"
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={styles.dialogFooter}>
          <Button
            variant="text"
            onClick={() => {
              onClose(true);
            }}
          >
            {t("components.SignInDialog.signUpButton")}
          </Button>
          <LoadingButton
            onClick={() => onSubmit(values)}
            type="button"
            variant="contained"
            disabled={!isValid}
            loading={loading}
            sx={styles.submit}
          >
            {t("components.SignInDialog.signInButton")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignInDialog;

const styles = mapStyles({
  root: {},
  dialog: {},
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "12px",
    py: 1,
  },
  dialogContent: {
    display: "flex",
    paddingBottom: 1,
    px: "12px",
  },
  dialogFooter: {
    px: "12px",
    paddingBottom: "12px",
    justifyContent: "space-between",
  },
  close: {
    marginRight: -1,
  },
  form: {
    width: "400px",
    gap: 1,
    mt: 1,
  },
  input: {
    width: "100%",
  },
  submit: {
    minWidth: "min(25%, 100px)",
  },
});
