import useSWR from "swr";
import { AspBrandsEnum, BrandsEnum } from "../typings/enums";
import { buildQueryParams, isInstanceOfEnum } from "../libs/object";
import { ApiResponse } from "../typings/interfaces";
import { CartDataModel, OrderModel } from "../typings/models";
import qs from "qs";

const useOrder = (id?: string) => {
  const opts: Record<string, any> = { populate: "*" };

  const searchParams = qs.stringify(opts, { encodeValuesOnly: true });

  const { data, isLoading, error, mutate, isValidating } = useSWR<
    ApiResponse<OrderModel>
  >(id ? `/orders/${id}?${searchParams}` : null);

  return {
    data: data?.data,
    isValidating,
    isLoading,
    error: (!data?.data && data?.error) || error || null,
    mutate,
  };
};

export default useOrder;
