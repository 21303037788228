import React from 'react';
import { Box, Typography } from '@mui/material';
import { mapStyles, mergeStyles } from "../libs/styles";

interface NoResultsProps {
  hide?: boolean;
  message: React.ReactNode;
  sx?: Style;
}

const NoResults: React.FC<NoResultsProps> = (props) => {
  const { message, hide, sx } = props;

  if (hide) return null;

  return (
    <Box sx={mergeStyles(styles.root, sx)}>
      <Typography sx={styles.text}>{message}</Typography>
    </Box>
  );
};

export default NoResults;

const styles = mapStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
    flexDirection: 'column',
  },
  text: {
    fontSize: {
      xs: 16,
      md: 24,
    },
    pointerEvents: 'none',
    userSelect: 'none',
    fontWeight: 600,
  },
});
