export const buildQueryParams = (data: Record<string, any>) => {
  const params = new URLSearchParams()
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(value => params.append(key, value.toString()))
    } else {
      if (value) {
        params.append(key, value?.toString())
      }
    }
  });
  return params.toString()
}

export const isInstanceOfEnum = (enumObject: any, value: any): boolean => {
  const options: string[] = Object.values(enumObject);
  return options.includes(value);
}

export const isObject = (item: any): boolean => item && typeof item === 'object';

export const deepMerge = (target: any, source: any): any => {
  if (!isObject(target) || !isObject(source)) return source;

  if (Array.isArray(target) && Array.isArray(source)) {
    return Array.from(new Set([...target, ...source]));
  }

  return Object.keys(source).reduce(
    (acc, key) => {
      if (!isObject(source[key])) {
        acc[key] = source[key];
        return acc;
      }

      if (key in target) {
        acc[key] = deepMerge(target[key], source[key]);
      } else {
        acc[key] = { ...source[key] };
      }
      return acc;
    },
    { ...target },
  );
};
