import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { mapStyles, mergeStyles } from "../libs/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AppContext } from "../contexts/AppContext";
import ProductList from "./ProductList";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import CreateOrderForm from "./CreateOrderForm";
import { CreateOrderFormModel } from "../typings/models";
import useBreakpoints from "../hooks/useBreakpoints";
import { createOrder } from "../api/orderApi";
import OrderSendConfirmation from "./OrderSendConfirmation";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import PrintOrderPage from "../pages/PrintOrderPage";
import useActualCartData from "../hooks/useActualCartData";
import useMount from "../hooks/useMount";
import useUnmount from "../hooks/useUnmount";
import { useSnackbar } from "notistack";
import { getSnackOptions } from "../libs/snackbar";
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from "@mui/icons-material";
import { ClientTypeEnum } from "../typings/enums";
import useQueryParams from "../hooks/useQueryParams";
import usePrint from "../hooks/usePrint";

interface CartProps {
  open?: boolean;
  onClose?: (state: boolean) => void;
  sx?: Style;
}

const CartDialog: React.FC<CartProps> = (props) => {
  const { open, onClose, sx } = props;
  const [ctx, updateCtx] = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [showNetPrice, setShowNetPrice] = useState<boolean>(false);
  const [showSendConfirmation, setShowSendConfirmation] =
    useState<boolean>(false);
  const [confirmationError, setConfirmationError] =
    useState<Nullable<string>>("");
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: cartData,
    mutate,
    isLoading,
    isValidating,
    error,
  } = useActualCartData(open ? ctx.cart : []);

  const { enqueueSnackbar } = useSnackbar();

  useMount(() => {
    mutate();
  });

  // useUnmount(() => {
  //   mutate();
  // });

  useEffect(() => {
    mutate();
  }, [ctx.cart]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.message,
        getSnackOptions("error", {
          heading: "Error",
        })
      );
    }
  }, [error, enqueueSnackbar, isValidating]);

  const { printableComponentRef, handlePrint } = usePrint();

  if (!open) return null;

  const handleFinishOrder = () => {
    navigate(`/orders/create`);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose && onClose(false)}
        maxWidth="xl"
        fullScreen={isMobile}
        sx={styles.dialog}
      >
        <DialogTitle sx={mergeStyles(styles.dialogTitle, styles.notPrintable)}>
          {t("components.Cart.cart")}
          <Box sx={styles.spacer} />
          {!ctx.token && ctx?.user?.clientType === ClientTypeEnum.Motoparts && (
            <Tooltip
              arrow
              placement="left"
              title={t("components.Cart.showNetPrice")}
            >
              <IconButton
                aria-label="close"
                onClick={() => setShowNetPrice((current) => !current)}
              >
                {showNetPrice ? (
                  <CheckBoxSharp />
                ) : (
                  <CheckBoxOutlineBlankSharp />
                )}
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={() => onClose && onClose(false)}
            sx={styles.close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          {ctx.cart?.length > 0 ? (
            !isLoading && cartData && cartData.order?.length > 0 ? (
              <ProductList
                items={cartData.order}
                cartMode
                cartData={cartData}
                isLoading={isValidating}
                displayItemsOnLoading
                displayNetPrice={showNetPrice}
                hideDiscountAndSubtotal
              />
            ) : (
              <ProductList
                items={ctx.cart}
                cartMode
                isLoading={isLoading || isValidating}
                displayItemsOnLoading
                displayNetPrice={showNetPrice}
                hideDiscountAndSubtotal
              />
            )
          ) : (
            <Stack sx={styles.emptyCart}>
              <Typography>{t("components.Cart.cartIsEmpty")}</Typography>
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={styles.dialogFooter}>
          {(ctx?.cart?.length || cartData?.order?.length) && (
            <Button onClick={handleFinishOrder}>
              {t("components.Cart.finishOrder")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {showSendConfirmation && (
        <OrderSendConfirmation
          error={confirmationError}
          open={showSendConfirmation}
          onClose={() => setShowSendConfirmation(false)}
        />
      )}
      <Box sx={styles.onlyPrintable} ref={printableComponentRef}>
        <PrintOrderPage />
      </Box>
    </>
  );
};

export default CartDialog;

const styles = mapStyles({
  root: {},
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "12px",
    py: 1,
  },
  dialogContent: {
    display: "flex",
    paddingBottom: 1,
    px: "12px",
  },
  dialogFooter: {
    px: "12px",
    paddingBottom: "12px",
  },
  spacer: {
    flexGrow: 1,
  },
  close: {
    marginRight: -1,
  },
  emptyCart: {
    alignItems: "center",
    justifyContent: "center",
    minWidth: "300px",
    minHeight: "150px",
    margin: "auto",
  },
  dialog: {
    "& .MuiDialog-paper": {
      "@media print": {
        flexDirection: "column-reverse",
      },
    },
  },
  notPrintable: {
    "@media print": {
      display: "none",
    },
  },
  onlyPrintable: {
    "@media screen": {
      display: "none",
    },
  },
});
