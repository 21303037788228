import { SvgIcon } from '@mui/material';
import React from 'react';

export const NaIcon: React.FC<any> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <polygon points="14.9,9.7 13.9,12.8 16.1,12.8 15.1,9.7 "/>
      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M11.1,14.9h-0.8l-3.1-4.8v4.8H6.5
	V9.1h0.8l3.1,4.8V9.1h0.7V14.9z M17.5,14.9h-0.7l-0.5-1.5h-2.7l-0.5,1.5h-0.7l2-5.9h1.2L17.5,14.9z"/>
    </SvgIcon>
  );
};

export const MpIcon: React.FC<any> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 355.8 161.9">
      <path d="M48.2,161.7c0.8,0,2.3-1.4,2.4-2.3c4.1-23.5,8.1-47,12.2-70.5c0.6-3.4,1.3-6.8,2-10.1c0.2,0,0.5,0,0.7,0.1
		c11.5,27.5,23,55,34.7,83c21.6-28,42.7-55.6,63.9-83.1c0.3,0.2,0.5,0.3,0.8,0.5c-4.8,27.4-9.5,54.8-14.3,82.4c17.2,0,34.2,0,51.3,0
		c3.1-16.8,6.2-33.4,9-50c0.5-3.1,1.7-3.7,4.5-3.7c32.5,0.1,64.9,0.1,97.4,0c1.7,0,3.5-0.1,5.2-0.5c15.2-4,25.2-13.7,28.6-28.8
		c3.5-15.3,5.3-31.1,8.6-46.5c3.1-14.6-6.4-32.4-26.7-32.1c-47.5,0.6-94.9,0.3-142.4,0c-6.6,0-10.8,1.6-14.7,7.1
		c-8.6,12.2-18,23.8-27.8,36.4c53.6,0,106.2,0,159.4,0c-2.5,5.9-5,11.3-6.9,16.9c-1.2,3.4-2.9,4.4-6.5,4.4
		c-52.7-0.1-105.4-0.1-158.1-0.1c-3,0-5,0.8-6.8,3.4c-3.1,4.5-6.7,8.8-10.2,13.2c-0.4-0.6-0.6-0.8-0.7-1
		C103.1,54.5,92.3,28.8,81.7,3c-0.9-2.3-2-2.9-4.4-2.9c-5.6,0.2-11.3,0.3-16.9,0C42.9-0.9,27,14.2,23.9,28.6
		c-2.6,12.1-4.6,24.4-6.8,36.6c-3.4,19.1-6.8,38.1-10.2,57.2c-2.3,13-4.6,26.1-6.9,39.4C16.3,161.8,32.3,161.8,48.2,161.7z"/>
    </SvgIcon>
  );
};

export const MotoPartsIcon: React.FC<any> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 1151.5 307.9">
      <path d="M446.1,161.7c0.8,0,2.3-1.4,2.4-2.3c4.1-23.5,8.1-47,12.2-70.5c0.6-3.4,1.3-6.8,2-10.1c0.2,0,0.5,0,0.7,0.1
		c11.5,27.5,23,55,34.7,83c21.6-28,42.7-55.6,63.9-83.1c0.3,0.2,0.5,0.3,0.8,0.5c-4.8,27.4-9.5,54.8-14.3,82.4c17.2,0,34.2,0,51.3,0
		c3.1-16.8,6.2-33.4,9-50c0.5-3.1,1.7-3.7,4.5-3.7c32.5,0.1,64.9,0.1,97.4,0c1.7,0,3.5-0.1,5.2-0.5c15.2-4,25.2-13.7,28.6-28.8
		c3.5-15.3,5.3-31.1,8.6-46.5c3.1-14.6-6.4-32.4-26.7-32.1C679,0.6,631.5,0.3,584.1,0c-6.6,0-10.8,1.6-14.7,7.1
		c-8.6,12.2-18,23.8-27.8,36.4c53.6,0,106.2,0,159.4,0c-2.5,5.9-5,11.3-6.9,16.9c-1.2,3.4-2.9,4.4-6.5,4.4
		c-52.7-0.1-105.4-0.1-158.1-0.1c-3,0-5,0.8-6.8,3.4c-3.1,4.5-6.7,8.8-10.2,13.2c-0.4-0.6-0.6-0.8-0.7-1
		C501,54.5,490.2,28.8,479.6,3c-0.9-2.3-2-2.9-4.4-2.9c-5.6,0.2-11.3,0.3-16.9,0c-17.6-1-33.5,14.1-36.6,28.5
		c-2.6,12.1-4.6,24.4-6.8,36.6c-3.4,19.1-6.8,38.1-10.2,57.2c-2.3,13-4.6,26.1-6.9,39.4C414.2,161.8,430.2,161.8,446.1,161.7z"/>
      <path d="M1074.2,236.5c22.9,0,45.7,0,68.6,0c1.3,0,2.7,0,4.2,0c1.6-8.8,3.1-17.3,4.6-26c-1.8,0-3.2,0-4.5,0
		c-28.1,0-56.2,0.4-84.3-0.2c-11-0.2-21.7,9.1-23.4,19.6c-0.8,4.9-1.8,9.8-2.7,14.7c-1.6,9-3.1,18-4.8,27.4c26.3,0,52.3,0,78.5,0
		c-0.6,3.4-1.1,6.4-1.7,9.6c-26.2,0-52.1,0-78.4,0c-1.6,8.7-3.1,17.3-4.7,26.2c1.6,0,2.8,0,4.1,0c28.4,0,56.7,0,85.1,0
		c10.8,0,20.5-7.8,22.7-18.6c1.9-9.5,3.5-19.1,5.2-28.6c0.8-4.7,1.6-9.4,2.4-14.6c-26.4,0-52.1,0-78.2,0c0.1-1.5,0.1-2.7,0.3-3.7
		C1068.3,236.5,1068.3,236.5,1074.2,236.5z"/>
      <path d="M120.5,210.5c-3.9,0-7.8-0.2-11.6,0.1c-1.4,0.1-3.2,0.8-4,1.9c-5.3,6.6-10.4,13.4-15.5,20.1c-6.7,8.7-13.5,17.5-20.5,26.6
		c-0.7-1.6-1.3-2.7-1.8-3.8c-5.8-13.9-11.6-27.7-17.2-41.6c-1-2.4-2.1-3.6-4.9-3.3c-2.8,0.3-5.8,0.3-8.6,0
		c-10.8-1.1-20.9,9.2-22.5,18.5c-2,11.9-4.3,23.7-6.4,35.6c-2.5,14.3-5,28.6-7.6,43c10.2,0,20.1,0,30.2,0
		c2.9-16.6,5.8-33.1,8.7-49.6c0.2,0,0.4,0,0.7,0c6.8,16.4,13.7,32.8,20.8,49.7c13-16.9,25.6-33.3,38.2-49.6c0.2,0.1,0.4,0.3,0.6,0.4
		c-2.8,16.3-5.6,32.5-8.5,49.3c9.6,0,18.9,0.1,28.1-0.1c0.8,0,2.2-1.7,2.4-2.7c3.6-19.2,7.1-38.3,10.4-57.6c1.3-7.7,2.7-15.4,3-23.2
		C134.8,216.6,128.1,210.6,120.5,210.5z"/>
      <path d="M878,210.3c-28.1,0.6-56.2,0.2-84.3,0.2c-1.5,0-2.9,0-4.4,0c-5.7,32.6-11.4,64.8-17.1,97.1c10.4,0,20.1,0,30.1,0
		c1.9-10.6,3.8-21.1,5.8-32.1c6.9,0,13.6-0.1,20.3,0.1c0.9,0,2,1.2,2.6,2.1c5.7,9.2,11.2,18.5,16.9,27.7c0.6,1,1.6,2.3,2.5,2.3
		c9.9,0.2,19.8,0.1,30.4,0.1c-6.6-11-12.9-21.4-19.5-32.3c2.1,0,3.5,0,4.9,0c12.2-0.1,21.7-8.1,23.8-20c1.5-8.3,3-16.6,4.4-24.9
		C896.7,217.5,888.4,210.1,878,210.3z M860.8,249.2c-16,0-31.8,0-48.1,0c0.7-4.1,1.3-8.1,2-12.3c16,0,32,0,48.4,0
		C862.3,241,861.6,245,860.8,249.2z"/>
      <path d="M751.2,210.5c-23.3,0-46.7,0.3-70-0.2c-9.7-0.2-20.7,8.5-22.6,19.2c-3.6,20.9-7.4,41.8-11,62.7c-0.9,5.1-1.7,10.1-2.5,15.4
		c10.2,0,19.9,0,30,0c1.9-10.6,3.8-21.2,5.7-31.9c16.2,0,32.2,0,48.2,0c-1.8,10.7-3.7,21.2-5.5,31.9c10.3,0,20.1,0,29.8,0
		c0.2-0.3,0.4-0.5,0.4-0.7c4.5-25.5,9.2-51.1,13.5-76.6C769.2,217.7,762.9,210.5,751.2,210.5z M733.5,249.3c-16,0-31.8,0-48.1,0
		c0.6-3.9,1.2-7.7,1.9-11.4c0.1-0.6,1.2-1.3,1.9-1.3c15.4-0.1,30.9-0.1,46.7-0.1C735,241.2,734.3,245.2,733.5,249.3z"/>
      <path d="M245.8,210.4c-23.6,0.3-47.2,0.5-70.8-0.1c-9.4-0.2-20.6,7.1-22.8,18.8c-3.8,19.9-7.2,39.8-10.5,59.8
		c-1.8,10.8,5.3,18.8,16.2,18.8c11.6,0,23.2,0,34.8,0s23.2,0,34.8,0c10.6,0,20.5-7.4,22.5-17.8c3.9-20.7,7.7-41.5,11-62.4
		C262.5,218.2,254.9,210.2,245.8,210.4z M221.5,281.4c-16,0-32,0-48.3,0c1.5-8.8,3-17.3,4.5-25.9c0.9-5.3,1.7-10.5,2.8-15.7
		c0.3-1.2,1.7-3.1,2.6-3.2c15.2-0.2,30.4-0.1,46.3-0.1C226.7,251.8,224.1,266.5,221.5,281.4z"/>
      <path d="M497.9,210.3c-23.5,0.6-46.9,0.2-70.4,0.2c-1.6,0-3.3,0.1-4.8,0.5c-10.8,3-17.7,13-17.6,25.6c25.9,0,51.9,0,78,0
		c-2.5,14.1-5,27.8-7.3,41.7c-0.4,2.7-1.5,3.4-4.1,3.4c-13.9-0.1-27.7-0.1-41.6-0.1c-1,0-2.1-0.2-3.2-0.3
		c1.9-10.8,3.7-21.2,5.5-31.8c-10.4,0-20.1,0-29.9,0c-0.3,0.9-0.6,1.7-0.7,2.5c-2.1,11.9-4.2,23.7-6.3,35.6
		c-2.2,12.6,5.7,20.6,17.7,20.3c22.5-0.6,44.9-0.2,67.4-0.2c12.1,0,21.3-7.8,23.5-19.7c3.5-19.3,7-38.6,10.3-57.9
		C516.7,217.4,508.2,210,497.9,210.3z"/>
      <path d="M625.3,210.3c-28.3,0.4-56.7,0.2-85.1,0.2c-1.4,0-2.7,0-4.4,0c-1.5,8.7-3,17.1-4.6,26.1c26.2,0,52,0,78.3,0
		c-1.5,4.6-2.9,8.7-4.3,12.9c-25.4,0-50.7,0-76.3,0c-3.4,19.4-6.8,38.6-10.3,58.1c10.2,0,20.1,0,30.1,0c1.9-10.7,3.8-21.2,5.8-32
		c1.8,0,3.1,0,4.5,0c18.1,0,36.2,0,54.3,0c11.2,0,20.4-7.5,22.6-18.5c1.9-9.2,3.7-18.5,5.1-27.8
		C642.9,217.9,634.9,210.2,625.3,210.3z"/>
      <path d="M278.8,236.5c13.2,0,26,0,39.2,0c-4.3,23.9-8.4,47.4-12.7,71.2c9.9,0,19.1,0.1,28.3-0.1c0.8,0,2.2-1.5,2.4-2.4
		c3.9-21.7,7.7-43.5,11.4-65.3c0.4-2.5,1.3-3.5,4-3.5c10.1,0.2,20.2,0.1,30.3,0.1c5.5,0,5.6,0,6.6-5.2c1.3-6.8,2.4-13.6,3.6-20.6
		c-36.5,0-72.5,0-108.7,0C281.8,219.2,280.4,227.6,278.8,236.5z"/>
      <path d="M917.1,210.7c-1.5,8.6-3,17-4.6,25.8c13.2,0,26.1,0,39.3,0c-4.3,23.9-8.4,47.4-12.7,71.1c10.3,0,20.1,0,30.3,0
		c4.2-23.8,8.4-47.3,12.6-71c13.1,0,26.1,0,39.3,0c1.6-8.8,3.1-17.3,4.6-25.8C989.4,210.7,953.3,210.7,917.1,210.7z"/>
    </SvgIcon>
  );
};

