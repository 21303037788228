import React from 'react';
import { Box } from '@mui/material';
import { ifStyles, mapStyles, mergeStyles } from "../libs/styles";
import theme from "../theme";
import { useTranslation } from "react-i18next";

interface ResponsiveTableParams {
  keepHead?: boolean;
  sx?: Style;
  children?: React.ReactNode;
}
export const ResponsiveTable: React.FC<ResponsiveTableParams> = (props: ResponsiveTableParams) => {
  return (
    <Box
      data-class="responsiveTable"
      sx={mergeStyles(styles.root, ifStyles(props.keepHead, styles.keepHead), props.sx)}
    >
      {props.children}
    </Box>
  );
};

const styles = mapStyles({
  root: {
    '--labelWidth': '110px',
    display: 'flex',
    flexGrow: '1',
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
      '.MuiSkeleton-root': {
        width: '100%',
        marginLeft: '-116px',
      },
      table: {
        border: 0,
        thead: {
          display: 'none',
        },
        tbody: {
          tr: {
            '&:last-child': {
              borderBottom: 0,
            },
          },
        },
        tr: {
          display: 'block',
          width: '100%',
          borderBottom: `3px solid ${theme.palette.divider}`,
        },
        td: {
          display: 'flex',
          borderBottom: '1px solid #ddd',
          width: '100%',
          minHeight: '32px',
          '&:before': {
            content: 'attr(data-label)',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            margin: 'auto 16px auto 0',
            display: 'flex',
            minWidth: 'var(--labelWidth, 110px)',
          },
          '&:last-child': {
            borderBottom: 0,
          },
        },
      },
    },
  },
  keepHead: {
    [theme.breakpoints.down('md')]: {
      table: {
        thead: {
          display: 'flex',
          flexDirection: 'column',
          position: 'sticky',
          top: 0,
          width: '100%',
          borderBottom: `1px solid ${theme.palette.divider}`,
          zIndex: 1,
        },
        th: {
          display: 'flex',
          borderBottom: '1px solid #ddd',
          width: '100%',
          minHeight: '32px',
          '&:last-child': {
            borderBottom: 0,
          },
        },
      },
    },
  }
});
