import useSWR from "swr";
import { ApiResponse } from "../typings/interfaces";
import { OrderModel } from "../typings/models";
import qs from "qs";

interface OrdersProps {
  populateAll?: boolean;
  populateOrders?: boolean;
  populateOrdersAsCount?: boolean;
}
const useOrders = <T = OrderModel>(props: OrdersProps = {}) => {
  const { populateAll, populateOrders, populateOrdersAsCount } = props;

  const opts: Record<string, any> = {
    sort: ["createdAt:desc"],
  };

  if (populateAll) {
    opts["populate"] = "*";
  }

  if (populateOrders) {
    opts["populate"] = {
      order: "*",
    };
  }

  if (populateOrdersAsCount) {
    opts["populate"] = {
      order: {
        count: "true",
      },
    };
  }

  const searchParams = qs.stringify(opts, { encodeValuesOnly: true });

  const { data, isLoading, error, mutate, isValidating } = useSWR<
    ApiResponse<T[]>
  >(`/orders?${searchParams}`);

  return {
    items: data?.data ?? [],
    isValidating,
    isLoading,
    error: (!data?.data && data?.error) || error || null,
    mutate,
  };
};

export default useOrders;
