import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useGuard from "../hooks/useGuard";
import { AppContext } from "../contexts/AppContext";

interface ProtectedRouteProps {
  redirectTo?: string;
}
export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { redirectTo = "/" } = props;
  const [ctx] = useContext(AppContext);
  const navigate = useNavigate();
  const isSignedIn = useGuard();

  if (!isSignedIn && !ctx?.token) {
    if (redirectTo) {
      navigate(redirectTo);
    }
    return null;
  }

  return <Outlet />;
};

export default ProtectedRoute;
