import React, { useContext, useEffect } from "react";
import useOrders from "../hooks/useOrders";
import useQueryParams from "../hooks/useQueryParams";
import useMount from "../hooks/useMount";
import { AppContext } from "../contexts/AppContext";

const TokenToContext: React.FC = () => {
  const [ctx, updateCtx] = useContext(AppContext);
  const { token = null } = useQueryParams();

  useEffect(() => {
    if (!ctx.isLoading) {
      updateCtx({ token });
    }
  }, [ctx.isLoading]);

  return <></>;
};

export default TokenToContext;
