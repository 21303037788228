const prefix = (keys: string[]): string => {
  if (keys.length === 0) return '';
  const [main, ...others] = keys;

  return `${main}${others.map((other) => `[${other}]`).join('')}`;
};

const appendUrl = (url: URLSearchParams, keys: string[], obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value === null || value === undefined) return;
    if (typeof value !== 'object') return url.append(prefix([...keys, key]), String(value));
    appendUrl(url, [...keys, key], value);
  });

  return url;
};

const urlSearch = (obj: Record<string, any>): string => {
  if (Object.keys(obj).length === 0) return '';
  const url = new URLSearchParams();
  appendUrl(url, [], obj);
  return '?' + url.toString();
};

export default urlSearch;
