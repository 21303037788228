import {
  CartDataModel,
  CreateOrderFormModel,
  OrderModel,
} from "../typings/models";
import { ApiHttpService } from "../services/apiHttpService";
import { ApiResponse } from "../typings/interfaces";

const api = new ApiHttpService();

export const createOrder = async (
  data: Partial<CartDataModel & CreateOrderFormModel>
): Promise<ApiResponse<OrderModel>> => {
  return await api.post<OrderModel>(`/orders`, data);
};

export const getListOrdersClean = async (): Promise<
  ApiResponse<OrderModel>
> => {
  return await api.get<OrderModel>(`/orders`, {});
};

export const getFullOrder = async (
  orderId: number
): Promise<ApiResponse<OrderModel>> => {
  return await api.get<OrderModel>(`/orders/${orderId}?populate=*`, {});
};
