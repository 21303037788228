import React from "react";
import { Box, Typography } from "@mui/material";
import { mapStyles } from "../libs/styles";

export const ForbiddenPage: React.FC = (props) => {
  return (
    <Box sx={styles.root}>
      <Typography variant="h1">Forbidden</Typography>
    </Box>
  );
};

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    margin: "0 auto",
    maxHeight: "var(--appHeight, 100vh)",
    overflow: "hidden",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ForbiddenPage;
