import { useEffect, useState } from 'react';

function useApiEffect(apiCallback: (ctrl: AbortSignal) => Promise<void>, deps: any[] = []): boolean {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const ctrl = new AbortController();
    setLoading(true);

    (async () => {
      try {
        if (!ctrl.signal.aborted) {
          await apiCallback(ctrl.signal);
        }
      } catch (e) {
        throw e;
      } finally {
        if (!ctrl.signal.aborted) {
          setLoading(false);
        }
      }
    })();

    return () => ctrl.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return loading;
}

export default useApiEffect;
