import { Alert, AlertTitle } from "@mui/material";
import React, { forwardRef, Ref, useCallback } from "react";
import {
  SnackbarContent,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from "notistack";
import { AlertProps } from "@mui/material/Alert/Alert";
import { mapStyles, mergeStyles } from "../libs/styles";

interface MuiSnackbarProps extends AlertProps {
  snackbarKey: SnackbarKey;
  message: SnackbarMessage;
  heading?: string;
}

const MuiSnackbar = forwardRef(
  (props: MuiSnackbarProps, ref: Ref<HTMLDivElement>, ...other) => {
    const {
      snackbarKey,
      message,
      heading,
      variant = "filled",
      ...rest
    } = props;
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(snackbarKey);
    }, [snackbarKey, closeSnackbar]);

    return (
      <SnackbarContent ref={ref}>
        <Alert
          onClose={handleDismiss}
          variant={variant}
          {...rest}
          sx={mergeStyles(styles.alert, rest.sx)}
        >
          {!!heading && (
            <AlertTitle sx={{ fontSize: "1.1428em" }}>{heading}</AlertTitle>
          )}
          {message}
        </Alert>
      </SnackbarContent>
    );
  }
);

export default MuiSnackbar;

const styles = mapStyles({
  alert: {
    boxShadow: 1,
    width: "100%",

    "& .MuiAlert-message": {
      display: "flex",
      flexDirection: "column",
      gap: 0.25,
    },

    "& .MuiAlertTitle-root": {
      mb: 0,
    },
  },
});
