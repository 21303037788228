import React from "react";
import { Box } from "@mui/material";
import theme from "../theme";
import { mapStyles, mergeStyles } from "../libs/styles";

interface DetailsListProps {
  children: React.ReactNode;
  sx?: Style;
}

const DetailsList: React.FC<DetailsListProps> = (props) => {
  const { children } = props;
  return (
    <Box sx={mergeStyles(styles.root, props.sx)} component="dl">
      {children}
    </Box>
  );
};

export default DetailsList;

const styles = mapStyles({
  root: {
    "--padding": "16px",
    fontSize: "16px",
    display: "grid",
    gridTemplateColumns: "100px auto",
    m: 0,

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "150px auto",
    },

    "& dd": {
      m: 0,
      p: "var(--padding, 16px)",
      borderBottom: `1px solid ${theme.palette.divider}`,
      gridColumn: "1 / 3",
      wordBreak: "break-word",
      "&:nth-last-of-type(1)": {
        borderBottom: "none",
      },
    },
    "& dt": {
      m: 0,
      p: "var(--padding, 16px)",
      position: "relative",
      borderBottom: `1px solid ${theme.palette.divider}`,

      "&:after": {
        content: '""',
        borderLeft: `1px solid ${theme.palette.divider}`,
        position: "absolute",
        top: "var(--padding, 16px)",
        bottom: "var(--padding, 16px)",
        right: 0,
      },

      "&:nth-last-of-type(1):not(:has(+ dd + dd))": {
        borderBottom: "none",
      },

      "& + dd": {
        gridColumn: "2 / 2",
      },
    },
  },
});
