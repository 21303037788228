import { createTheme, ThemeOptions } from "@mui/material";

export const defaultThemeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: "#b71c1c",
    },
    mode: "light",
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "4em",
    },
    h2: {
      fontSize: "3.5em",
    },
    h3: {
      fontSize: "3em",
    },
    h4: {
      fontSize: "2.5em",
    },
    h5: {
      fontSize: "2em",
    },
    h6: {
      fontSize: "1.5em",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        // InputLabelProps: {
        //   shrink: false,
        // },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          scrollBehavior: "smooth",
        },
      },
    },
  },
};
export const theme = createTheme(defaultThemeConfig);
