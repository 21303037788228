import React, { useContext, useMemo, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Card,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { mapStyles, mergeStyles } from "../libs/styles";
import useOrder from "../hooks/useOrder";
import Header from "../components/Header";
import ProductList from "../components/ProductList";
import { OrderItemModel } from "../typings/models";
import DateTime from "../components/DateTime";
import DetailsList from "../components/DetailsList";
import { useTranslation } from "react-i18next";
import ForbiddenPage from "./ForbidenPage";
import { ClientTypeEnum } from "../typings/enums";
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from "@mui/icons-material";
import { AppContext } from "../contexts/AppContext";
import useQueryParams from "../hooks/useQueryParams";
import PrintOrderPage from "./PrintOrderPage";
import usePrint from "../hooks/usePrint";
import PrintIcon from "@mui/icons-material/Print";

export const ViewOrderPage: React.FC = (props) => {
  const [ctx] = useContext(AppContext);
  const { id } = useParams();
  const { data, isLoading, error } = useOrder(id);
  const { t } = useTranslation();
  const { printableComponentRef, handlePrint } = usePrint();

  const isMotoparts = useMemo(
    () => ctx?.user?.clientType === ClientTypeEnum.Motoparts,
    [ctx]
  );

  return (
    <>
      <Box sx={mergeStyles(styles.root, styles.onlyScreen)}>
        <Header
          breadcrumbs={
            <Breadcrumbs>
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to="/"
              >
                {t("components.Breadcrumbs.search")}
              </Link>
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to="/orders"
              >
                {t("components.Breadcrumbs.orders")}
              </Link>
              {error || !data ? (
                <Typography color="text.primary">#{id}</Typography>
              ) : (
                <Typography color="text.primary">
                  #{id}
                  <Typography component="span" color="text.secondary">
                    &nbsp;(
                    <DateTime value={data?.createdAt} component="span" />
                    )&nbsp;
                  </Typography>
                </Typography>
              )}
            </Breadcrumbs>
          }
        />
        <Box sx={styles.container}>
          {error && <ForbiddenPage />}

          {data && (
            <Stack gap={2} direction="row" sx={styles.cardsWrapper}>
              <Card variant="outlined" sx={styles.card}>
                <DetailsList sx={styles.detailsList}>
                  <Box component="dt">{t("pages.ViewOrderPage.name")}</Box>
                  <Box component="dd">{data.clientName}</Box>
                  <Box component="dt">{t("pages.ViewOrderPage.email")}</Box>
                  <Box component="dd">{data.email}</Box>
                  <Box component="dt">
                    {t("pages.ViewOrderPage.phoneNumber")}
                  </Box>
                  <Box component="dd">{data.phoneNumber}</Box>
                  {Boolean(data.clientNotes) && (
                    <>
                      <Box component="dt">
                        {t("pages.ViewOrderPage.clientNotes")}
                      </Box>
                      <Box component="dd">{data.clientNotes}</Box>
                    </>
                  )}
                  {isMotoparts && Boolean(data.adminNotes) && (
                    <>
                      <Box component="dt">
                        {t("pages.ViewOrderPage.adminNotes")}
                      </Box>
                      <Box component="dd">{data.adminNotes}</Box>
                    </>
                  )}
                </DetailsList>
              </Card>
              <Card variant="outlined" sx={styles.card}>
                <DetailsList sx={styles.detailsList}>
                  <Box component="dt">{t("pages.ViewOrderPage.createdAt")}</Box>
                  <Box component="dd">
                    <DateTime value={data.createdAt} />
                  </Box>
                  <Box component="dt">{t("pages.ViewOrderPage.updatedAt")}</Box>
                  <Box component="dd">
                    <DateTime value={data.updatedAt} />
                  </Box>
                  <Box component="dt">{t("pages.ViewOrderPage.paid")}</Box>
                  <Box component="dd">
                    {data.paid || 0}€&nbsp;
                    <small>
                      (
                      {(((data.paid ?? 0) * 100) / (data.total || 0)).toFixed(
                        2
                      )}
                      %)
                    </small>
                  </Box>
                  <Box component="dt">
                    {t("pages.ViewOrderPage.remainingPayment")}
                  </Box>
                  <Box component="dd">
                    {data.remainingPayment ?? data.total - (data.paid ?? 0)} €
                  </Box>
                  <Box component="dt">{t("pages.ViewOrderPage.status")}</Box>
                  <Box component="dd">
                    {t(`enums.OrderStatusEnum.${data.status}`)}
                  </Box>
                </DetailsList>
              </Card>
            </Stack>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Typography variant="h5">
              {t("pages.ViewOrderPage.orderedProducts")}
            </Typography>
            <Box sx={styles.spacer} />
            <IconButton onClick={handlePrint}>
              <PrintIcon />
            </IconButton>
          </Stack>
          <ProductList
            cartData={data}
            items={data?.order as OrderItemModel[]}
            isLoading={isLoading}
            viewMode
            sx={styles.productList}
          />
        </Box>
      </Box>
      <Box sx={styles.onlyPrintable} ref={printableComponentRef}>
        <PrintOrderPage order={data} />
      </Box>
    </>
  );
};

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxHeight: "var(--appHeight, 100vh)",
    overflow: "hidden",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
    p: 2,
    gap: 2,
  },
  cardsWrapper: {
    width: "100%",
    mb: 2,
  },
  card: {
    display: "flex",
    flexGrow: 1,
    flexBasis: "50%",
    px: 1,
    py: 0.5,
    mb: "auto",
  },
  spacer: {
    flexGrow: 1,
  },
  detailsList: {
    flexGrow: 1,
  },
  productList: {},
  onlyScreen: {
    "@media print": {
      display: "none",
    },
  },
  onlyPrintable: {
    "@media screen": {
      display: "none",
    },
  },
});

export default ViewOrderPage;
