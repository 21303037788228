import { SWRConfiguration } from "swr";
import compare from "../libs/swrCompareResponse";
import fetcher from "../libs/fetcher";
import fetcher2 from "../libs/fetcher2";

export const SWR_REFRESH_INTERVAL = 0; // 60000;
export const SWR_DEDUPING_INTERVAL = 0; // 5000;

export default {
  fetcher: fetcher2,
  dedupingInterval: SWR_DEDUPING_INTERVAL,
  refreshInterval: SWR_REFRESH_INTERVAL,
  revalidateOnFocus: false,
  revalidateIfStale: false,
  revalidateOnReconnect: true,
  revalidateOnMount: true,
  compare,
} as SWRConfiguration;
