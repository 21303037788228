import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoints = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = !isMobile;

  return {
    isSmall,
    isMobile,
    isLarge,
  };
};

export default useBreakpoints;
