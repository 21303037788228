import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { mapStyles, mergeStyles } from "../libs/styles";
import Header from "../components/Header";
import ProductList from "../components/ProductList";
import { CreateOrderFormModel, OrderModel } from "../typings/models";
import { useTranslation } from "react-i18next";
import useActualCartData from "../hooks/useActualCartData";
import { AppContext } from "../contexts/AppContext";
import CreateOrderForm from "../components/CreateOrderForm";
import { createOrder } from "../api/orderApi";
import OrderSendConfirmation from "../components/OrderSendConfirmation";
import PrintOrderPage from "./PrintOrderPage";
import { useReactToPrint } from "react-to-print";
import { ClientTypeEnum } from "../typings/enums";
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from "@mui/icons-material";
import usePrint from "../hooks/usePrint";
import PrintIcon from "@mui/icons-material/Print";
import { useSnackbar } from "notistack";
import { getSnackOptions } from "../libs/snackbar";

export const CreateOrderPage: React.FC = (props) => {
  const [ctx] = useContext(AppContext);
  const [currentFormData, setCurrentFormData] =
    useState<Nullable<CreateOrderFormModel>>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showNetPrice, setShowNetPrice] = useState<boolean>(false);
  const [showSendConfirmation, setShowSendConfirmation] =
    useState<Nullable<OrderModel>>(null);
  const [confirmationError, setConfirmationError] =
    useState<Nullable<string>>("");
  const [customDiscount, setCustomDiscount] = useState<string>("");
  const {
    data: cartData,
    isLoading,
    mutate,
    error,
  } = useActualCartData(ctx.cart, customDiscount);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { printableComponentRef, handlePrint } = usePrint();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error.message,
        getSnackOptions("error", {
          heading: "Error",
        })
      );
    }
  }, [error]);

  const sendOrder = async (formData: CreateOrderFormModel) => {
    if (!cartData || cartData.order.length < 1) {
      return;
    }
    setLoading(true);
    const allData = { ...cartData, ...formData };
    const { discount, adminNotes, paid, status, ...onlyClientAllowedData } =
      allData;
    const res = await createOrder(
      ctx?.user?.clientType === ClientTypeEnum.Motoparts
        ? allData
        : onlyClientAllowedData
    );
    setLoading(false);
    console.log(res);
    if (res.data === null && res.error) {
      enqueueSnackbar(
        res.error.message,
        getSnackOptions("error", {
          heading: "Error",
        })
      );
      if (res.error.status === 400) {
        setConfirmationError(res.error.message);
      } else {
        setConfirmationError(t("components.Cart.orderSentError"));
      }
    } else {
      setConfirmationError("");
    }
    setShowSendConfirmation(res.data);
  };

  const handleCustomDiscountChange = (value: string) => {
    setCustomDiscount(value);
    mutate();
  };

  return (
    <>
      <Box sx={mergeStyles(styles.root, styles.onlyScreen)}>
        <Header
          breadcrumbs={
            <Breadcrumbs>
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to="/"
              >
                {t("components.Breadcrumbs.search")}
              </Link>
              <Typography color="text.primary">
                {t("components.Breadcrumbs.createOrder")}
              </Typography>
            </Breadcrumbs>
          }
          hasCart={false}
        />

        <Box sx={styles.container}>
          <Typography variant="h5">
            {t("pages.CreateOrderPage.contactInformation")}
          </Typography>
          <CreateOrderForm
            contacts={ctx.user}
            onChange={setCurrentFormData}
            onSubmit={sendOrder}
            loading={loading || isLoading}
            onCustomDiscountChange={(value) =>
              handleCustomDiscountChange(value)
            }
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Typography variant="h5">
                {t("pages.CreateOrderPage.productsInCart")}
              </Typography>
              <Box sx={styles.spacer} />
              {!ctx.token &&
                ctx?.user?.clientType === ClientTypeEnum.Motoparts && (
                  <Box>
                    <Tooltip
                      arrow
                      placement="left"
                      title={t("pages.CreateOrderPage.showNetPrice")}
                    >
                      <IconButton
                        aria-label="close"
                        onClick={() => setShowNetPrice((current) => !current)}
                      >
                        {showNetPrice ? (
                          <CheckBoxSharp />
                        ) : (
                          <CheckBoxOutlineBlankSharp />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              <IconButton onClick={handlePrint}>
                <PrintIcon />
              </IconButton>
            </Stack>

            <ProductList
              cartData={cartData}
              items={cartData?.order ?? ctx.cart}
              isLoading={isLoading}
              viewMode
              displayItemsOnLoading
              displayNetPrice={showNetPrice}
              sx={styles.productList}
            />
          </CreateOrderForm>
        </Box>

        {showSendConfirmation && (
          <OrderSendConfirmation
            error={confirmationError}
            open={!!showSendConfirmation}
            onClose={() => {
              navigate(`/orders/${showSendConfirmation.id}`);
              setShowSendConfirmation(null);
            }}
          />
        )}
      </Box>
      <Box sx={styles.onlyPrintable} ref={printableComponentRef}>
        <PrintOrderPage order={{ ...currentFormData, ...cartData }} />
      </Box>
    </>
  );
};

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxHeight: "var(--appHeight, 100vh)",
    overflow: "hidden",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
    p: 2,
    gap: 2,
  },
  spacer: {
    flexGrow: 1,
  },
  detailsList: {
    flexGrow: 1,
  },
  productList: {},
  onlyScreen: {
    "@media print": {
      display: "none",
    },
  },
  onlyPrintable: {
    "@media screen": {
      display: "none",
    },
  },
});

export default CreateOrderPage;
