import React from 'react';
import { IconButton, Popover } from '@mui/material';
import { mapStyles } from "../libs/styles";
import { AspBrandsEnum, BrandsEnum } from "../typings/enums";
import GoogleImages from "./GoogleImages";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CloseIcon from '@mui/icons-material/Close';

interface GoogleImagesPopoverProps {
  brand?: BrandsEnum|AspBrandsEnum|string;
  partNumber: string;
  sx?: Style;
}

const GoogleImagesPopover: React.FC<GoogleImagesPopoverProps> = (props) => {
  const { brand, partNumber, sx } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${brand}-${partNumber}` : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick} sx={sx}>
        <ImageSearchIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 0, left: 0 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={styles.popover}
        PaperProps={{
          style: { width: '100%' },
        }}
      >
        <GoogleImages partNumber={partNumber} brand={brand} />
        <IconButton onClick={handleClose} sx={styles.close}>
          <CloseIcon />
        </IconButton>
      </Popover>
    </>
  );
};

export default GoogleImagesPopover;

const styles = mapStyles({
  root: {
  },
  popover: {
    minWidth: '50%',
    minHeight: '150px',
  },
  close: {
    position: 'absolute',
    top: '-0.25em',
    right: '-0.25em',
  },
});
