import useSWR from "swr";
import { ApiResponse } from "../typings/interfaces";
import { CartDataModel, OrderItemModel } from "../typings/models";
import fetcher2 from "../libs/fetcher2";
import { useEffect } from "react";
import urlSearch from "../libs/urlSearch";

interface ActualCartRequestBody {
  cart: OrderItemModel[];
  discount?: string;
}
const useActualCartData = (items: OrderItemModel[] = [], discount?: string) => {
  let body: ActualCartRequestBody = { cart: items };
  if (!!discount) {
    body = { ...body, discount };
  }

  const opts: Record<string, any> = {};

  if (discount) {
    opts.specialDiscount = discount; // Just to have new url to fetch, backend ignore this param
  }

  const { data, isLoading, error, mutate, isValidating } = useSWR<
    ApiResponse<CartDataModel>
  >(items.length ? `/price-list/user/cart-content${urlSearch(opts)}` : null, {
    fetcher: (url, init) =>
      fetcher2(url, {
        ...init,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }),
  });

  return {
    data: data?.data,
    isValidating,
    isLoading,
    error: (!data?.data && data?.error) || error || null,
    mutate,
  };
};

export default useActualCartData;
