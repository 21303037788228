import useSWR from "swr";
import { AspBrandsEnum, BrandsEnum } from "../typings/enums";
import { buildQueryParams } from "../libs/object";
import { ApiResponse } from "../typings/interfaces";

const useSearchGoogleImages = <T>(
  brand: BrandsEnum | AspBrandsEnum | string = "",
  search: string,
  perPage?: number
) => {
  let url = null;
  if (search) {
    url = `/price-list/user/google-images/?${buildQueryParams({
      search: `${[brand, search].join("+")}`,
      perPage,
    })}`;
  }

  const { data, isLoading, error, mutate, isValidating } =
    useSWR<ApiResponse<T[]>>(url);

  return {
    items: data?.data ?? [],
    isValidating,
    isLoading,
    error: (!data?.data && data?.error) || error || null,
    mutate,
  };
};

export default useSearchGoogleImages;
