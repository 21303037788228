import DataProvider, { createContext } from "./ContextProvider";
import {
  CreateOrderFormModel,
  OrderItemModel,
  UserModel,
} from "../typings/models";

export interface AppContextProps {
  isLoading: boolean; // Context loading status. Don't modify context before isLoading become false
  token: Nullable<string>;
  user: Nullable<UserModel>;
  jwt: Nullable<string>;
  cart: OrderItemModel[];
}

const defaultContext: AppContextProps = {
  isLoading: true,
  token: null,
  user: null,
  jwt: null,
  cart: [],
};

export const AppContext = createContext(defaultContext, "AppContext");

export default DataProvider;
