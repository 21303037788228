export const ruTranslation = {
  components: {
    Breadcrumbs: {
      search: "Поиск запчастей",
      orders: "Список заказов",
      order: "Заказ",
      createOrder: "Новый заказ",
      profile: "Мой профиль",
    },
    Cart: {
      cart: "Корзина",
      showNetPrice: "Показать цены нетто",
      orderSentError: "Заказ НЕБЫЛ отправлен",
      cartIsEmpty: "Корзина пуста",
      finishOrder: "Оформить заказ",
    },
    CreateOrderForm: {
      name: "Имя",
      email: "Email",
      phoneNumber: "Телефон",
      clientNotes: "Комментарии к заказу",
      adminNotes: "Комментарии админа",
      paid: "Оплачено",
      status: "Статус",
      discount: "Скидка",
      send: "Отправить",
      disclaimer:
        "После отправки менеджер с вами свяжется для обсуждения доступности товаров, сроков доставки и оплате заказа",
    },
    GoogleImages: {
      noImages: "Картинок не найдено",
    },
    OrderSendConfirmation: {
      successTitle: "Заказ был отправлен",
      errorTitle: "Заказ НЕБЫЛ отправлен",
      closeAndCleanCart: "Закрыть и очистить корзину",
      successMessage:
        "Заказ был успешно отправлен менеджеру. В скором времени с вами свяжутся.",
      close: "Закрыть",
    },
    ProductList: {
      brand: "Марка",
      partNumber: "Номер запчасти",
      name: "Название",
      qty: "Количество",
      price: "Цена",
      netPrice: "Цена Нетто",
      add: "Добавить",
      remove: "Удалить",
      subTotal: "Всего",
      discount: "Скидка",
      total: "Итого",
      paid: "Оплачено",
      remainingPayment: "Осталось оплатить",
      notFound: "Не было найдено результатов",
      qtyInput: "Количество",
      sum: "Сумма",
      netPriceSum: "Сумма Нетто",
      searchSomething: "Используйте форму для поиска запчастей",
    },
    SearchForm: {
      brand: "Марка",
      partNumber: "Номер запчасти",
    },
    SignInDialog: {
      title: "Войти",
      email: "Email",
      password: "Пароль",
      signInButton: "Войти",
      signUpButton: "Зарегистрироваться",
      signInSuccess: "Успешный вход",
      signInError: "Ошибка входа",
    },
    SignUpDialog: {
      title: "Регистрация",
      username: "Имя пользователя",
      email: "Email",
      password: "Пароль",
      rePassword: "Подтверждение пароля",
      phoneNumber: "Номер телефона",
      signInButton: "Войти",
      signUpButton: "Зарегистрироваться",
      signUpSuccess: "Успешная регистрация",
      signUpSuccessHelper: "Подтвердите ваш email по ссылке из письма",
      signUpError: "Ошибка регистрации",
    },
    Header: {
      orderHistory: "История заказов",
      logOut: "Выйти",
    },
  },
  enums: {
    OrderStatusEnum: {
      paymentRequired: "Ожидает оплаты",
      pending: "Ожидает заказа",
      ordered: "Заказан",
      inProgress: "В процессе",
      delivered: "Доставлен",
      finished: "Завершен",
      dontOrder: "Не заказывать",
      null: "",
    },
  },
  pages: {
    CreateOrderPage: {
      contactInformation: "Контактная информация",
      productsInCart: "Товары в корзине",
      showNetPrice: "Показать цены нетто",
    },
    OrderListPage: {
      createdAt: "Дата создания",
      orderedItems: "Количество товаров",
      total: "Стоимость",
      status: "Статус",
    },
    ViewOrderPage: {
      name: "Имя",
      email: "Email",
      phoneNumber: "Телефон",
      clientNotes: "Комментарии",
      createdAt: "Дата создания",
      updatedAt: "Дата обновления",
      paid: "Оплачено",
      remainingPayment: "Осталось оплатить",
      status: "Статус",
      orderedProducts: "Заказанные товары",
      showNetPrice: "Показать цены нетто",
    },
  },
};
