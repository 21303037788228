import { FormikErrors, FormikTouched } from 'formik';

interface PropError {
  touched: boolean;
  errors: string | undefined;
}

const parsePropError = <T = any>(touched: FormikTouched<T>, errors: FormikErrors<T>, path: string): PropError => {
  const props = path.split('.');

  return props.reduce(
    (acc: any, item: string) => {
      const _touched = typeof acc.touched === 'boolean' ? acc.touched : acc.touched[item] ? acc.touched[item] : false;
      const _errors = typeof acc.errors === 'string' ? acc.errors : acc.errors[item] ? acc.errors[item] : '';
      return {
        touched: _touched,
        errors: _errors,
      } as any;
    },
    { touched, errors },
  );
};

export const errorUtil = <T = any>(touched: FormikTouched<T>, errors: FormikErrors<T>) => {
  return {
    getError: (path: string) => {
      const propError = parsePropError(touched, errors, path);
      return (propError.touched && propError.errors) || '';
    },
    isInvalid: (path: string) => {
      const propError = parsePropError(touched, errors, path);
      return propError.touched && Boolean(propError.errors);
    },
  };
};
