import React, { useContext, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { mapStyles } from "../libs/styles";
import { AppContext } from "../contexts/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MpIcon } from "./Icons";
import SearchForm from "./SearchForm";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import { FormatListBulletedSharp, LogoutSharp } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import SignInDialog from "./SignInDialog";
import SignUpDialog from "./SignUpDialog";
import useQueryParams from "../hooks/useQueryParams";
import { SearchParams } from "../typings/interfaces";
import theme from "../theme";
import CartDialog from "./CartDialog";
import UserOrdersCount from "./UserOrdersCount";
import useAuth from "../hooks/useAuth";

interface HeaderProps {
  showSearchForm?: boolean;
  hasCart?: boolean;
  onSearch?: (search: SearchParams) => void;
  isLoading?: boolean;
  breadcrumbs?: React.ReactNode;
  autoOpenSignIn?: boolean;
  autoOpenSignUp?: boolean;
  sx?: Style;
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    showSearchForm,
    hasCart = true,
    onSearch,
    isLoading,
    breadcrumbs,
    autoOpenSignIn = false,
    autoOpenSignUp = false,
    sx,
  } = props;

  const { hasCart: urlHasCart, hideLogo: urlHideLogo } = useQueryParams();

  const [ctx, updateCtx] = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const userMenuAnchor = useRef(null);
  const [openCart, setOpenCart] = useState<boolean>(false);
  const [openUserMenu, setOpenUserMenu] = useState<boolean>(false);
  const [openSignIn, setOpenSignIn] = useState<boolean>(autoOpenSignIn);
  const [openSignUp, setOpenSignUp] = useState<boolean>(autoOpenSignUp);

  const { isSignedIn, signOut } = useAuth();

  const cartAvailable = hasCart || urlHasCart === "true";

  return (
    <>
      <Stack direction="row" gap={1} sx={styles.searchFormWrapper}>
        {urlHideLogo !== "true" && (
          <MpIcon sx={styles.mpIcon} onClick={() => navigate("/")} />
        )}
        {showSearchForm && (
          <SearchForm
            isLoading={isLoading}
            onSearch={(data) => {
              if (isSignedIn || !!ctx.token) {
                if (onSearch) {
                  onSearch(data);
                }
              } else {
                setOpenSignIn(true);
              }
            }}
          />
        )}
        {!!breadcrumbs && <Box sx={styles.breadcrumbs}>{breadcrumbs}</Box>}
        <Stack direction="row" gap={1} sx={styles.buttonsWrapper}>
          {cartAvailable && (
            <Button
              onClick={() => setOpenCart(true)}
              variant="contained"
              type="button"
              size="small"
              sx={styles.button}
            >
              <ShoppingCartIcon /> ({ctx.cart.length})
            </Button>
          )}

          {!ctx.token ? (
            isSignedIn ? (
              <>
                <Button
                  onClick={() => {
                    setOpenUserMenu(true);
                  }}
                  variant="contained"
                  type="button"
                  ref={userMenuAnchor}
                  sx={styles.button}
                >
                  <PersonIcon />
                </Button>
                <Menu
                  anchorEl={userMenuAnchor.current}
                  open={openUserMenu}
                  onClose={() => setOpenUserMenu(false)}
                >
                  {/*
                <MenuItem
                  onClick={() => {
                    setOpenUserMenu(false);
                  }}
                >
                  Profile
                </MenuItem>
                */}
                  <MenuItem
                    onClick={() => {
                      navigate("/profile");
                    }}
                    disabled
                    sx={styles.currentUserName}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText>{ctx.user?.email}</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      setOpenUserMenu(false);
                      navigate("/orders");
                    }}
                  >
                    <ListItemIcon>
                      <Badge badgeContent={<UserOrdersCount />}>
                        <FormatListBulletedSharp fontSize="small" />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText>
                      {t("components.Header.orderHistory")}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenUserMenu(false);
                      signOut();
                    }}
                  >
                    <ListItemIcon>
                      <LogoutSharp fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("components.Header.logOut")}</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                onClick={() => {
                  setOpenSignIn(true);
                }}
                variant="contained"
                type="button"
                sx={styles.button}
              >
                <LoginIcon />
              </Button>
            )
          ) : null}
        </Stack>
      </Stack>

      <SignInDialog
        open={openSignIn}
        onClose={(switchToSignUp) => {
          if (autoOpenSignIn) {
            navigate("/");
          }
          setOpenSignIn(false);
          if (switchToSignUp) {
            setOpenSignUp(true);
          }
        }}
      />
      <SignUpDialog
        open={openSignUp}
        onClose={(switchToSignIn) => {
          if (autoOpenSignUp) {
            navigate("/");
          }
          setOpenSignUp(false);
          if (switchToSignIn) {
            setOpenSignIn(true);
          }
        }}
      />

      {cartAvailable && <CartDialog open={openCart} onClose={setOpenCart} />}
    </>
  );
};

export default Header;

const styles = mapStyles({
  searchFormWrapper: {
    p: 2,
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "72px",
  },
  mpIcon: {
    fontSize: "56px",
    height: "auto",
    margin: "auto 6px auto 0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  breadcrumbs: {
    mr: "auto",
    ml: 2,
  },
  buttonsWrapper: {
    ml: "auto",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "12px",
      right: "12px",
      height: "40px",
      flexDirection: "row-reverse",
      zIndex: 1,
    },
  },
  button: {
    height: "40px",
  },
  currentUserName: {
    fontWeight: "bold",
  },
});
