import { ApiResponse } from "../typings/interfaces";
import fetcher from "../libs/fetcher2";

export class ApiHttpService {
  private readonly fetcher: <T = any>(
    url: string,
    init: RequestInit
  ) => Promise<ApiResponse<T>>;

  constructor() {
    this.fetcher = async (url, init) => fetcher(url, init);
  }

  public async get<T>(
    path: string,
    params: Params<string> | string = "",
    init: RequestInit = {}
  ): Promise<ApiResponse<T>> {
    const urlParams: string = new URLSearchParams(params).toString();
    return this.fetcher<T>(`${path}?${urlParams}`, { ...init, method: "GET" });
  }

  public async post<T, K = any>(
    path: string,
    data: Nullable<K> = null,
    init: RequestInit = {}
  ): Promise<ApiResponse<T>> {
    const body = typeof data === "string" ? data : JSON.stringify(data);
    return this.fetcher<T>(path, { ...init, body, method: "POST" });
  }

  public async patch<T, K = any>(
    path: string,
    data: Nullable<K> = null,
    init: RequestInit = {}
  ): Promise<ApiResponse<T>> {
    const body = typeof data === "string" ? data : JSON.stringify(data);
    return this.fetcher<T>(path, { ...init, body, method: "PATCH" });
  }

  public async delete<T>(
    path: string,
    init: RequestInit = {}
  ): Promise<ApiResponse<T>> {
    return this.fetcher<T>(path, { ...init, method: "DELETE" });
  }
}
