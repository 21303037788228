import React from "react";
import { CircularProgress, Stack } from "@mui/material";
import { mapStyles, mergeStyles } from "../libs/styles";
import useSearchGoogleImages from "../hooks/useSearchGoogleImages";
import { AspBrandsEnum, BrandsEnum } from "../typings/enums";
import NoResults from "./NoResults";
import { useTranslation } from "react-i18next";

interface GoogleImagesProps {
  brand?: BrandsEnum | AspBrandsEnum | string;
  partNumber: string;
  count?: number;
  sx?: Style;
}

const GoogleImages: React.FC<GoogleImagesProps> = (props) => {
  const { brand, partNumber, count, sx } = props;

  const { items, isLoading } = useSearchGoogleImages<string>(
    brand ?? "",
    partNumber ?? "",
    count
  );
  const { t } = useTranslation();

  return (
    <Stack sx={mergeStyles(styles.root, sx)}>
      {isLoading && <CircularProgress sx={styles.spinner} />}
      {!isLoading &&
        items &&
        items.length > 0 &&
        items?.map((image, index) => (
          <img src={image} alt={`#${index}`} key={index} />
        ))}
      <NoResults
        hide={!items || items?.length > 0 || isLoading}
        message={t("components.GoogleImages.noImages")}
      />
    </Stack>
  );
};

export default GoogleImages;

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    minWidth: "150px",
    minHeight: "150px",
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
    gap: 1,
    padding: "4px",
  },
  spinner: {
    margin: "auto",
  },
});
