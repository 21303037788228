import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { mapStyles } from "../libs/styles";
import useOrders from "../hooks/useOrders";
import DateTime from "../components/DateTime";
import { Count } from "../typings/interfaces";
import Header from "../components/Header";
import { OrderModel } from "../typings/models";
import { useTranslation } from "react-i18next";

export const OrderListPage: React.FC = (props) => {
  const { items } = useOrders<OrderModel<Count>>({
    populateOrdersAsCount: true,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenOrder = (id: number) => {
    navigate(`/orders/${id}`);
  };

  return (
    <Box sx={styles.root}>
      <Header
        breadcrumbs={
          <Breadcrumbs>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/"
            >
              {t("components.Breadcrumbs.search")}
            </Link>
            <Typography color="text.primary">
              {t("components.Breadcrumbs.profile")}
            </Typography>
          </Breadcrumbs>
        }
      />
    </Box>
  );
};

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxHeight: "var(--appHeight, 100vh)",
    overflow: "hidden",
    width: "100%",
  },
  bodyRow: {
    cursor: "pointer",
  },
});

export default OrderListPage;
