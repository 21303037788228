import React, { useContext, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { mapStyles } from "../libs/styles";
import { CreateOrderFormModel, UserModel } from "../typings/models";
import useMount from "../hooks/useMount";
import { errorUtil } from "../libs/formikError";
import LoadingButton from "@mui/lab/LoadingButton";
import useBreakpoints from "../hooks/useBreakpoints";
import { useTranslation } from "react-i18next";
import { BrandsEnum, ClientTypeEnum, OrderStatusEnum } from "../typings/enums";
import { AppContext } from "../contexts/AppContext";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const requiredMessage = "Acest câmp este obligatoriu"; // todo: translate

const validationSchema = Yup.object({
  clientName: Yup.string().required(requiredMessage),
  email: Yup.string().email().required(requiredMessage),
  phoneNumber: Yup.string().required(requiredMessage),
  clientNotes: Yup.string(),
  adminNotes: Yup.string(),
  discount: Yup.string(),
  paid: Yup.number(),
});

const FORM_DEFAULT_VALUES: CreateOrderFormModel = {
  clientName: "",
  email: "",
  phoneNumber: "",
  clientNotes: "",
};

interface CreateOrderFormProps extends React.PropsWithChildren {
  contacts: Nullable<UserModel>;
  onChange?: (data: CreateOrderFormModel) => void;
  onSubmit?: (data: CreateOrderFormModel) => void;
  submitLabel?: string;
  loading?: boolean;
  disclaimer?: string;
  onCustomDiscountChange: (discount: string) => void;
  sx?: Style;
}

const CreateOrderForm: React.FC<CreateOrderFormProps> = (props) => {
  const [ctx] = useContext(AppContext);
  const { t } = useTranslation();
  const {
    contacts,
    onChange,
    onSubmit,
    onCustomDiscountChange = () => {},
    submitLabel,
    loading,
    sx,
    children,
    disclaimer = t("components.CreateOrderForm.disclaimer"),
  } = props;

  const {
    values,
    handleSubmit,
    errors,
    touched,
    isValid,
    setValues,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormik({
    initialValues: FORM_DEFAULT_VALUES,
    validationSchema,
    onSubmit: onSubmit || ((data: CreateOrderFormModel) => {}),
  });

  const isMotoparts = useMemo(
    () => ctx?.user?.clientType === ClientTypeEnum.Motoparts,
    [ctx]
  );

  useEffect(() => {
    const {
      username: clientName = "",
      email = "",
      phoneNumber = "",
    } = contacts ?? {};
    setValues(
      !!contacts
        ? { ...FORM_DEFAULT_VALUES, clientName, email, phoneNumber }
        : FORM_DEFAULT_VALUES
    );
  }, [contacts, setValues]);

  useMount(async () => {
    await validateForm();
  });

  const { isMobile } = useBreakpoints();

  const errorHandling = errorUtil(touched, errors);

  const handleChange = async (name: string, value: any, touch?: boolean) => {
    await setFieldValue(name, value);
    if (touch) {
      await setFieldTouched(name, true);
    }
  };

  const handleBlur = async (name: string, touch?: boolean) => {
    await setFieldTouched(name, touch);
    if (onChange) {
      onChange(values);
    }
  };

  const handleApplyDiscount = () => {
    onCustomDiscountChange(values.discount || "");
  };

  return (
    <Box sx={styles.form} component="form" onSubmit={handleSubmit}>
      <Stack gap={2} direction="column">
        <Stack gap={2} direction={isMobile ? "column" : "row"}>
          <Stack gap={2} direction="column" sx={styles.formColumn}>
            <TextField
              label={t("components.CreateOrderForm.name")}
              value={values.clientName}
              onChange={(ev) => handleChange("clientName", ev.target.value)}
              onBlur={() => handleBlur("clientName", true)}
              error={errorHandling.isInvalid("clientName")}
              required
              sx={styles.input}
            />
            <TextField
              label={t("components.CreateOrderForm.email")}
              value={values.email}
              onChange={(ev) => handleChange("email", ev.target.value)}
              onBlur={() => handleBlur("email", true)}
              error={errorHandling.isInvalid("email")}
              required
              sx={styles.input}
            />
            <TextField
              label={t("components.CreateOrderForm.phoneNumber")}
              value={values.phoneNumber}
              onChange={(ev) => handleChange("phoneNumber", ev.target.value)}
              onBlur={() => handleBlur("phoneNumber", true)}
              error={errorHandling.isInvalid("phoneNumber")}
              required
              sx={styles.input}
            />
          </Stack>
          <Stack gap={2} direction="column" sx={styles.formColumn}>
            <TextField
              label={t("components.CreateOrderForm.clientNotes")}
              value={values.clientNotes}
              onChange={(ev) => handleChange("clientNotes", ev.target.value)}
              onBlur={() => handleBlur("clientNotes", true)}
              error={errorHandling.isInvalid("clientNotes")}
              multiline
              rows={7}
              sx={styles.input}
            />
          </Stack>
        </Stack>

        {isMotoparts && (
          <>
            <Divider />
            <Stack gap={2} direction={isMobile ? "column" : "row"}>
              <Stack gap={2} direction="column" sx={styles.formColumn}>
                <TextField
                  label={t("components.CreateOrderForm.discount")}
                  value={values.discount ?? ""}
                  onChange={(ev) => handleChange("discount", ev.target.value)}
                  onBlur={() => {
                    handleBlur("discount", true);
                    handleApplyDiscount();
                  }}
                  error={errorHandling.isInvalid("discount")}
                  sx={styles.input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleApplyDiscount()}
                          edge="end"
                        >
                          <CurrencyExchangeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="number"
                  label={t("components.CreateOrderForm.paid")}
                  value={values.paid ?? ""}
                  onChange={(ev) => handleChange("paid", +ev.target.value)}
                  onBlur={() => handleBlur("paid", true)}
                  error={errorHandling.isInvalid("paid")}
                  sx={styles.input}
                />
                <TextField
                  select
                  label={t("components.CreateOrderForm.status")}
                  value={values.status ?? ""}
                  onChange={(ev) => handleChange("status", ev.target.value)}
                  onBlur={() => handleBlur("status", true)}
                  error={errorHandling.isInvalid("status")}
                  sx={styles.input}
                >
                  <MenuItem value={OrderStatusEnum.PaymentRequired}>
                    {t(
                      `enums.OrderStatusEnum.${OrderStatusEnum.PaymentRequired}`
                    )}
                  </MenuItem>
                  <MenuItem value={OrderStatusEnum.Pending}>
                    {t(`enums.OrderStatusEnum.${OrderStatusEnum.Pending}`)}
                  </MenuItem>
                  <MenuItem value={OrderStatusEnum.Ordered}>
                    {t(`enums.OrderStatusEnum.${OrderStatusEnum.Ordered}`)}
                  </MenuItem>
                  <MenuItem value={OrderStatusEnum.InProgress}>
                    {t(`enums.OrderStatusEnum.${OrderStatusEnum.InProgress}`)}
                  </MenuItem>
                  <MenuItem value={OrderStatusEnum.Delivered}>
                    {t(`enums.OrderStatusEnum.${OrderStatusEnum.Delivered}`)}
                  </MenuItem>
                  <MenuItem value={OrderStatusEnum.Finished}>
                    {t(`enums.OrderStatusEnum.${OrderStatusEnum.Finished}`)}
                  </MenuItem>
                  <MenuItem value={OrderStatusEnum.DontOrder}>
                    {t(`enums.OrderStatusEnum.${OrderStatusEnum.DontOrder}`)}
                  </MenuItem>
                </TextField>
              </Stack>
              <Stack gap={2} direction="column" sx={styles.formColumn}>
                <TextField
                  label={t("components.CreateOrderForm.adminNotes")}
                  value={values.adminNotes ?? ""}
                  onChange={(ev) => handleChange("adminNotes", ev.target.value)}
                  onBlur={() => handleBlur("adminNotes", true)}
                  error={errorHandling.isInvalid("adminNotes")}
                  multiline
                  rows={7}
                  sx={styles.input}
                />
              </Stack>
            </Stack>
          </>
        )}

        {Boolean(disclaimer) && <Typography>{disclaimer}</Typography>}

        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!isValid}
          loading={loading}
          sx={styles.submit}
        >
          {submitLabel || t("components.CreateOrderForm.send")}
        </LoadingButton>

        {children}
      </Stack>
    </Box>
  );
};

export default CreateOrderForm;

const styles = mapStyles({
  root: {},
  form: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
  formColumn: {
    flexGrow: 1,
    flexBasis: "100%",
  },
  submit: {
    minWidth: "min(25%, 100px)",
    "@media print": {
      display: "none",
    },
  },
});
