export enum AspBrandsEnum {
  Tgb = "tgb", //'13889',
  Linhai = "linhai", //'48984',
  Segway = "segway", // '64743',
  XrwRacingParts = "xrwracingparts", //'5077',
  Fasst = "fasst", //'6740',
  AccessMotor = "accessmotor", //'9956',
  Tjd = "tjd", //'19777',
  SharkAccessories = "sharkaccessories", //'20669',
  ComeUp = "comeup", //'21389',
  MaximaUsa = "maximausa", //'37267',
  Itp = "itp", //'39101',
  Acerbis = "acerbis", //'39811',
  HouserRacing = "houserracing", //'41958',
  Ls2Helmets = "ls2helmets", //'44297',
  ProArmor = "proarmor", //'50094',
  FoxShox = "foxshox", //'54186',
  Ngk = "ngk", //'59709',
  CarlisleUsa = "carlisleusa", //'60385',
  ArcticCat = "arcticcat", //'64725',
}

export enum BrandsEnum {
  Asp = "asp",
  Bmw = "bmw",
  Brp = "brp",
  Cfmoto = "cfmoto",
  Ducati = "ducati",
  HarleyDavidson = "harley-davidson",
  Honda = "honda",
  Husqvarna = "husqvarna",
  Indian = "indian",
  Kawasaki = "kawasaki",
  Ktm = "ktm",
  Linhai = "linhai", // ASP '48984',
  Piaggio = "piaggio",
  Polaris = "polaris",
  Suzuki = "suzuki",
  Segway = "segway", // ASP '64743',
  Tgb = "tgb", // ASP '13889',
  Triumph = "triumph",
  Yamaha = "yamaha",
}

export enum ClientTypeEnum {
  Client = "Client",
  Pro = "Pro",
  Motoparts = "Motoparts",
}

export enum OrderStatusEnum {
  PaymentRequired = "paymentRequired",
  Pending = "pending",
  Ordered = "ordered",
  InProgress = "inProgress",
  Delivered = "delivered",
  Finished = "finished",
  DontOrder = "dontOrder",
}
