import { AlertProps } from "@mui/material/Alert/Alert";
import MuiSnackbar from "../components/MuiSnackbar";
import React from "react";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export const getSnackOptions = (
  severity: AlertProps["severity"],
  other: {
    options?: OptionsObject;
    alertProps?: AlertProps;
    heading?: string;
  } = {}
): OptionsObject => ({
  ...other.options,
  content: (key: SnackbarKey, message: SnackbarMessage) => (
    <MuiSnackbar
      snackbarKey={key}
      message={message}
      heading={other.heading}
      severity={severity}
      {...other.alertProps}
    />
  ),
});
