import axios, { AxiosResponse } from "axios";

import { UserModel } from "../typings/models";
import { API_URL } from "../constants/common";
import { ApiResponse } from "../typings/interfaces";

export interface SignInFormModel {
  email: string;
  password: string;
}
export interface SignInResponseModel {
  jwt: string;
  user: UserModel;
}
export const signInRequest = async (
  params: SignInFormModel
): Promise<ApiResponse<SignInResponseModel>> => {
  const body = {
    identifier: params.email,
    password: params.password,
  };
  return await axios.post<SignInResponseModel>(`${API_URL}/auth/local`, body);
};

export interface SignUpFormModel {
  email: string;
  password: string;
  rePassword: string;
  username: string;
  phoneNumber: string;
}
export interface SignUpResponseModel {
  jwt: string;
  user: UserModel;
}
export const signUpRequest = async (
  params: SignUpFormModel
): Promise<ApiResponse<SignUpResponseModel>> => {
  const { rePassword, ...body } = params;
  return await axios.post<SignUpResponseModel>(
    `${API_URL}/auth/local/register`,
    body
  );
};
