export const API_URL_LOCAL = "http://localhost:1337/api";
export const API_URL_PROD = "https://api.parts.motoparts.md/api";
export const API_URL =
  process.env.NODE_ENV === "production" ? API_URL_PROD : API_URL_LOCAL;

// export const MAILER_URL_LOCAL = "http://localhost:3000/mailer";
// export const MAILER_URL_PROD = "/mailer";
// export const MAILER_URL =
//   process.env.NODE_ENV === "production" ? MAILER_URL_PROD : MAILER_URL_LOCAL;

// export const GOOGLE_IMAGES_URL_LOCAL = "http://localhost:1337/api";
// export const GOOGLE_IMAGES_URL_PROD = "/api";
// export const GOOGLE_IMAGES_URL =
//   process.env.NODE_ENV === "production"
//     ? GOOGLE_IMAGES_URL_PROD
//     : GOOGLE_IMAGES_URL_LOCAL;
