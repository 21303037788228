import React, { Suspense, useEffect, useMemo } from "react";
import {
  createTheme,
  CssBaseline,
  IconButton,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import { SWRConfig } from "swr";
import SearchPage from "./pages/SearchPage";
import swrConfig from "./constants/swr";
import useQueryParams from "./hooks/useQueryParams";
import { deepMerge } from "./libs/object";
import { defaultThemeConfig } from "./constants/theme";
import DataProvider from "./contexts/AppContext";
import i18n, { InitOptions } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { roTranslation } from "./i18next/roTranslation";
import { enTranslation } from "./i18next/enTranslation";
import { ruTranslation } from "./i18next/ruTranslation";
import "iframe-resizer/js/iframeResizer.contentWindow";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrintOrderPage from "./pages/PrintOrderPage";
import NotFoundPage from "./pages/NotFoundPage";
import OrderListPage from "./pages/OrderListPage";
import ViewOrderPage from "./pages/ViewOrderPage";
import CreateOrderPage from "./pages/CreateOrderPage";
import TokenToContext from "./components/TokenToContext";
import { SnackbarKey, SnackbarProvider } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ProtectedRoute from "./components/ProtectedRoute";
import ProfilePage from "./pages/ProfilePage";

const i18nextConfig: InitOptions = {
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources: {
    en: {
      translation: enTranslation,
    },
    ro: {
      translation: roTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
  },
  // lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  supportedLngs: ["en", "ro", "ru"],
  load: "languageOnly",
  defaultNS: "translation",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  // debug: true,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(i18nextConfig);

const appHeight = (iframe: string) => {
  const height = iframe ? "100%" : `${window.innerHeight}px`;
  document.documentElement.style.setProperty("--appHeight", height);
};
export const App: React.FC = (props) => {
  const notistackRef = React.createRef<any>();

  // ?themeMode=light&mainColor=rgb(255,152,0)&backgroundColor=rgb(0,255,142)&token=asd
  const { mainColor, backgroundColor, themeMode, lang, iframe } =
    useQueryParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.addEventListener("resize", () => appHeight(iframe));
  }, []);

  useEffect(() => {
    appHeight(iframe);
  }, [iframe]);

  useEffect(() => {
    if (
      lang &&
      Array.isArray(i18nextConfig.supportedLngs) &&
      i18nextConfig.supportedLngs.includes(lang) &&
      lang !== i18n.language
    ) {
      console.log("languageChanged", lang);
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const onClickDismiss = (key?: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const urlModifiedTheme: ThemeOptions = useMemo(
    () =>
      deepMerge(defaultThemeConfig, {
        palette: {
          ...(backgroundColor
            ? {
                background: {
                  default: backgroundColor,
                },
              }
            : {}),
          ...(mainColor
            ? {
                primary: {
                  main: mainColor,
                },
              }
            : {}),
          ...(themeMode && ["dark", "light"].includes(themeMode)
            ? {
                mode: themeMode,
              }
            : {}),
        },
      } as ThemeOptions),
    [backgroundColor, mainColor, themeMode]
  );

  return (
    <DataProvider>
      <ThemeProvider theme={createTheme(urlModifiedTheme)}>
        <SnackbarProvider
          dense
          preventDuplicate
          maxSnack={3}
          ref={notistackRef}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          action={(key) => (
            <IconButton
              onClick={onClickDismiss(key)}
              sx={{ color: "common.white" }}
            >
              <CloseIcon />
            </IconButton>
          )}
        >
          <SWRConfig value={swrConfig}>
            <CssBaseline />
            <TokenToContext />
            <BrowserRouter>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<SearchPage />} />
                  <Route
                    path="/sign-in"
                    element={<SearchPage autoOpenSignIn />}
                  />
                  <Route
                    path="/sign-up"
                    element={<SearchPage autoOpenSignUp />}
                  />
                  <Route element={<ProtectedRoute redirectTo="/sign-in" />}>
                    <Route path="/orders" element={<OrderListPage />} />
                    <Route
                      path="/orders/create"
                      element={<CreateOrderPage />}
                    />
                    <Route path="/orders/print" element={<PrintOrderPage />} />
                    <Route path="/orders/:id" element={<ViewOrderPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                  </Route>
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </SWRConfig>
        </SnackbarProvider>
      </ThemeProvider>
    </DataProvider>
  );
};

export default App;
