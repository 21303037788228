import { useEffect, useLayoutEffect } from 'react';

function useMount(callback: () => void): void {
  useEffect(() => {
    callback();
    // @ts-ignore
  }, []);
}

export default useMount;
