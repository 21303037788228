import {
  getLocalStorageItem,
  wipeLocalStorageItem,
} from "../services/storageService";
import { STATE_KEY } from "../constants/storageKeys";
import { AppContextProps } from "../contexts";
import {
  ApiResponse,
  ApiResponseError,
  ApiResponseSuccess,
} from "../typings/interfaces";
import { API_URL } from "../constants/common";

const defaults: RequestInit = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export async function decorateInit(
  init: RequestInit = {}
): Promise<RequestInit> {
  const state = await getLocalStorageItem<AppContextProps>(STATE_KEY);

  const bearer = state?.token || state?.jwt;

  const authHeader: HeadersInit = bearer
    ? { Authorization: `Bearer ${bearer}` }
    : {};

  return {
    ...defaults,
    ...init,
    headers: {
      ...defaults.headers,
      ...authHeader,
      ...init.headers,
    },
  };
}

async function fetcher<T = any>(
  url: string,
  init?: RequestInit
): Promise<ApiResponse<T>> {
  try {
    const requestInit = await decorateInit(init);
    if (Array.isArray(url)) url = url[0];
    const response = await fetch(API_URL + url, requestInit);
    if (response.status === 401) {
      // await wipeLocalStorageItem(STATE_KEY);
      // if (window.location.pathname.includes('/app/')) {
      //   window.location.href = '/';
      // }
      console.error("[fetcher]", await response.clone().text());
      return response.json();
    }
    if (response.status === 403) {
      // if (window.location.pathname !== '/app/') {
      //   window.location.href = '/app/403';
      // }
      console.error("[fetcher]", await response.clone().text());
      return response.json();
    }
    if (response.status >= 200 && response.status < 400) {
      if (response.status === 204) {
        return response.json(); // null as any as T satisfies ApiResponseSuccess<T>;
      }

      if (response.headers.get("Content-Type")?.includes("application/json")) {
        return (await response.json()) satisfies ApiResponseSuccess<T>;
      } else {
        const text = await response.text();
        return { data: text as T };
      }
    }

    const result = (await response.json()) as ApiResponseError;

    console.error("[fetcher]", JSON.stringify(result));
    return result;
  } catch (error: any) {
    console.error(`[fetcher]`, error);
    return {
      data: null,
      error: {
        name: "GenericError",
        message: error?.message || "Generic Error",
        status: 500,
      },
    } satisfies ApiResponse<T>;
  }
}

export default fetcher;
