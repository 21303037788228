import React, { useEffect, useState } from "react";
import useSearchProducts from "../hooks/useSearchProducts";
import { SearchParams } from "../typings/interfaces";
import ProductList from "../components/ProductList";
import { Box } from "@mui/material";
import { mapStyles } from "../libs/styles";
import useQueryParams from "../hooks/useQueryParams";
import { ProductModel } from "../typings/models";
import Header from "../components/Header";
import { useSnackbar } from "notistack";
import { getSnackOptions } from "../libs/snackbar";

interface SearchPageProps {
  autoOpenSignIn?: boolean;
  autoOpenSignUp?: boolean;
}
export const SearchPage: React.FC<SearchPageProps> = (props) => {
  const { autoOpenSignIn, autoOpenSignUp } = props;
  const {
    brand: urlBrand,
    search: urlSearch,
    hasCart: urlHasCart = "true",
  } = useQueryParams();
  const [searchParams, setSearchParams] =
    useState<Nullable<SearchParams>>(null);
  const { items, isLoading, mutate, error, isValidating } =
    useSearchProducts<ProductModel>(
      searchParams?.brand ?? "",
      searchParams?.search ?? ""
    );

  const { enqueueSnackbar } = useSnackbar();

  const handleSearch = (search: SearchParams) => {
    mutate();
    setSearchParams(search);
  };

  useEffect(() => {
    if (urlBrand && urlSearch) {
      setSearchParams({ brand: urlBrand, search: urlSearch });
    }
  }, []);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        error?.message,
        getSnackOptions("error", {
          heading: "Error",
        })
      );
    }
  }, [error, enqueueSnackbar, isValidating]);

  const hasCart = urlHasCart === "true";

  return (
    <Box sx={styles.root}>
      <Header
        onSearch={handleSearch}
        isLoading={isLoading}
        showSearchForm
        autoOpenSignIn={autoOpenSignIn}
        autoOpenSignUp={autoOpenSignUp}
      />
      <ProductList
        searchParams={searchParams}
        items={items}
        isLoading={isLoading}
        hasCart={hasCart}
        sx={styles.productList}
      />
      <Box data-iframe-height></Box>
    </Box>
  );
};

const styles = mapStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxHeight: "var(--appHeight, 100vh)",
    overflow: "hidden",
    width: "100%",
  },
  productList: {
    px: 1,
  },
});

export default SearchPage;
